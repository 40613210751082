<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="9" class="d-flex justify-start align-center"><h2>Menu Admin</h2></v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        small
        depressed
        @click="
          formData = {
            id: '',
            name: '',
            link: '',
            status: 1,
          }
          formNavigationDrawer = true
          $refs.formChild.clearForm()
        "
        >Tambah</v-btn
      >
      <v-btn
        class="ml-1"
        color="primary"
        small
        depressed
        outlined
        @click="
          getDataSorting()
          sortingDialog = true
        "
        >Urutkan</v-btn
      >
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>
          <h5>Filter</h5>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field label="Cari" outlined dense v-model="search" :hide-details="true" />
            </v-col>
            <v-col cols="4">
              <v-select
                :hide-details="true"
                :items="statuses"
                outlined
                dense
                item-text="name"
                item-value="value"
                v-model="selectedStatus"
                label="Status"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small @click="getData" depressed>Filter</v-btn>
          <v-btn
            color="primary"
            small
            depressed
            outlined
            class="ml-3"
            @click="
              search = ''
              selectedStatus = ''
              getData()
            "
            >Reset</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="data"
        :options.sync="options"
        :server-items-length="total"
        :loading="loadingTable"
        class="elevation-1"
        :items-per-page="limit"
        :footer-props="{
          'items-per-page-options': [5, 10, 50, 100],
        }"
      >
        <template v-slot:[`item.link`]="{ item }">
          {{ item.link ? item.link : '#' }}
        </template>
        <template v-slot:[`item.option`]="{ item }">
          <v-row>
            <v-col cols="12">
              <v-btn
                color="warning"
                x-small
                class="mr-3"
                @click="
                  formNavigationDrawer = true
                  formData = {
                    id: item.id,
                    name: item.name,
                    link: item.link,
                    status: item.status,
                    parent_id: item.parent_id,
                  }
                "
                >Ubah</v-btn
              >
              <v-btn
                color="error"
                x-small
                @click="
                  deleteDialog = true
                  selectedDelete = item
                "
                >Hapus</v-btn
              >
              <v-btn
                v-if="item.link == ''"
                color="info"
                class="ml-3"
                x-small
                @click="
                  getDataSorting(item.id)
                  sortingDialog = true
                "
                >Sorting</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          {{ item.status == 1 ? 'Aktif' : 'Tidak Aktif' }}
        </template>
      </v-data-table>
    </v-col>

    <v-snackbar v-model="alert.status" :timeout="timeout" :color="alert.success ? 'success' : 'error'" right bottom>
      {{ alert.message }}
    </v-snackbar>

    <v-dialog v-model="deleteDialog" max-width="400">
      <v-card>
        <v-card-title>Yakin dihapus?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              color="error"
              small
              depressed
              class="mr-2"
              :loading="loadingDeleteButton"
              :disabled="loadingDeleteButton"
              @click="deleteData"
              >Ya</v-btn
            >
            <v-btn color="error" outlined depressed small @click="deleteDialog = false">Tidak</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- FORM EDIT -->
    <v-navigation-drawer v-model="formNavigationDrawer" fixed bottom temporary right width="380">
      <Form :selectedData="formData" ref="formChild" :closeNav="closeNav" :setAlert="setAlert" :getData="getData" />
    </v-navigation-drawer>

    <v-dialog v-model="sortingDialog" max-width="500">
      <v-card>
        <v-card-title>Urutkan Menu</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" id="sorting-data">
              <ul class="list-group reorder_list_data" id="reorder_list_data"></ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            small
            depressed
            @click="submitSorting()"
            :loading="loadingButtonSorting"
            :disabled="loadingButtonSorting"
            >Simpan</v-btn
          >
          <v-btn color="primary" small depressed outlined @click="sortingDialog = false">Batal</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical, mdiRefresh, mdiFileOutline } from '@mdi/js'
import axios from 'axios'
import Form from './components/Form.vue'

export default {
  components: { Form },
  setup() {
    return {
      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiRefresh,
        mdiFileOutline,
      },
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('user'))
    },
  },
  data() {
    return {
      alert: {
        status: false,
        success: false,
        message: '',
      },
      timeout: 1200,
      loadingTable: false,
      total: 0,
      data: [],
      limit: 10,
      search: '',
      statuses: [
        { name: 'Pilih Semua', value: '' },
        { name: 'Aktif', value: 1 },
        { name: 'Tidak Aktif', value: 0 },
      ],
      selectedStatus: '',
      headers: [
        { text: 'Nama', value: 'name', sortable: false },
        { text: 'Link', value: 'link', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Opsi?', value: 'option', sortable: false },
      ],
      options: {},
      selectedDelete: null,
      formData: {
        id: '',
        name: '',
        link: '',
        status: 1,
        parent_id: null,
      },
      deleteDialog: false,
      formNavigationDrawer: false,
      loadingDeleteButton: false,
      sortingDialog: false,
      loadingButtonSorting: false,
    }
  },
  mounted() {
    this.getData()
  },
  watch: {
    options: {
      handler() {
        this.getData()
      },
      deep: true,
    },
  },
  methods: {
    getData() {
      this.loadingTable = true
      this.data = []
      const token = localStorage.getItem('token')
      const { page, itemsPerPage } = this.options
      const params = {
        page,
        limit: itemsPerPage,
        q: this.search,
        status: this.selectedStatus ?? '',
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}menu-admin?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingTable = false
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.total = data.data.count
              this.data = data.data.rows
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          this.loadingTable = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    setAlert(value) {
      this.alert = value
    },
    closeNav() {
      this.formNavigationDrawer = false
    },
    deleteData() {
      this.loadingDeleteButton = true
      const token = localStorage.getItem('token')
      axios
        .delete(`${process.env.VUE_APP_API_ENDPOINT}menu-admin/${this.selectedDelete.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          this.loadingDeleteButton = false
          this.deleteDialog = false
          if (res.status == 200) {
            this.alert = {
              status: true,
              success: res.data.success,
              message: res.data.message,
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
          this.getData()
          this.$refs.formChild.getParent()
        })
        .catch(err => {
          this.loadingDeleteButton = false
          this.deleteDialog = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    getDataSorting(parent_id = '') {
      const token = localStorage.getItem('token')
      const params = {
        page: 1,
        limit: 99,
        q: '',
        status: '',
        is_parent: parent_id == '' ? 1 : 0,
        parent_id: parent_id,
      }
      const paramsString = new URLSearchParams(params).toString()
      axios
        .get(`${process.env.VUE_APP_API_ENDPOINT}menu-admin?${paramsString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          if (res.status == 200) {
            const data = res.data
            if (data.success) {
              this.initialSorting(data.data.rows)
            } else {
              this.alert = {
                status: true,
                success: false,
                message: data.message,
              }
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
        })
        .catch(err => {
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
    initialSorting(data = []) {
      const reorder_list_data = document.getElementById('reorder_list_data')
      reorder_list_data.innerHTML = 'Loading...'

      let html = ''
      if (data.length > 0) {
        for (const item of data) {
          html += `<li class="list-group-item" data-id="${item.id}">${item.name}</li>`
        }
      } else {
        html += '<span style="text-align:center;">Tidak ada data</span>'
      }
      reorder_list_data.innerHTML = html
      if (data.length > 0) {
        enableDragSort('reorder_list_data')
      }

      function enableDragSort(listClass) {
        const sortableLists = document.getElementsByClassName(listClass)
        Array.prototype.map.call(sortableLists, list => {
          enableDragList(list)
        })
      }

      function enableDragList(list) {
        Array.prototype.map.call(list.children, item => {
          enableDragItem(item)
        })
      }

      function enableDragItem(item) {
        item.setAttribute('draggable', true)
        item.ondrag = handleDrag
        item.ondragend = handleDrop
      }

      function handleDrag(item) {
        const selectedItem = item.target,
          list = selectedItem.parentNode,
          x = event.clientX,
          y = event.clientY

        selectedItem.classList.add('drag-sort-active')
        let swapItem = document.elementFromPoint(x, y) === null ? selectedItem : document.elementFromPoint(x, y)

        if (list === swapItem.parentNode) {
          swapItem = swapItem !== selectedItem.nextSibling ? swapItem : swapItem.nextSibling
          list.insertBefore(selectedItem, swapItem)
        }
      }

      function handleDrop(item) {
        item.target.classList.remove('drag-sort-active')
      }
    },
    submitSorting() {
      this.loadingButtonSorting = true
      const elementsOrder = document.querySelectorAll('.reorder_list_data li')
      let requestData = []
      for (const item of elementsOrder) {
        requestData.push(item.dataset.id)
      }

      const token = localStorage.getItem('token')
      axios
        .put(
          `${process.env.VUE_APP_API_ENDPOINT}menu-admin/sorting`,
          {
            ids: requestData,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(res => {
          this.loadingButtonSorting = false
          if (res.status == 200) {
            this.alert = {
              status: true,
              success: res.data.success,
              message: res.data.message,
            }
          } else {
            this.alert = {
              status: true,
              success: false,
              message: res.statusText,
            }
          }
          this.sortingDialog = false
          this.getData()
          this.$refs.formChild.getParent()
        })
        .catch(err => {
          this.loadingButtonSorting = false
          this.sortingDialog = false
          const response = err.response
          const data = response.data
          this.alert = {
            status: true,
            success: false,
            message: data.message,
          }
          if (data.message == 'Anda tidak diberikan akses!') {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            this.$router.push({ name: 'login' })
          }
        })
    },
  },
}
</script>

<style>
#sorting-data .list-group {
  list-style: none;
  padding-left: 2px;
}
#sorting-data .list-group-item {
  padding: 10px 20px;
  display: block;
  border: 1px solid #dddddd;
  margin-bottom: 0px !important;
}
li.drag-sort-active {
  background: transparent;
  color: transparent;
  border: 1px solid #4ca1af;
}

span.drag-sort-active {
  background: transparent;
  color: transparent;
}

.reorder_list_data li {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.reorder_list_data li:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>